import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService implements OnDestroy {
  private _breadcrumbs$: BehaviorSubject<Breadcrumb[]>;
  private currentBreadcrumb$: BehaviorSubject<Breadcrumb>;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._breadcrumbs$ = new BehaviorSubject([]);
    this.currentBreadcrumb$ = new BehaviorSubject(null);
    this.initializeBreadcrumbHandling();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private initializeBreadcrumbHandling(): void {
    const route$ = this.router.events.pipe(
      filter(
        (event) =>
          event instanceof NavigationEnd ||
          (event instanceof Scroll &&
            event.routerEvent instanceof NavigationEnd)
      ),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
    );

    this.subscription = this.calculateBreadcrumbs(route$).subscribe(
      (breadcrumbs) => {
        console.log(
          '🚀 ~ BreadcrumbService ~ initializeBreadcrumbHandling ~ breadcrumbs:',
          breadcrumbs
        );
        this._breadcrumbs$.next(breadcrumbs);
      }
    );
  }

  private calculateBreadcrumbs(
    route$: Observable<ActivatedRoute>
  ): Observable<Breadcrumb[]> {
    return route$.pipe(
      switchMap((route) => {
        const breadcrumbs = this.buildBreadcrumbs(route.root);
        this._breadcrumbs$.next(breadcrumbs);
        return this.currentBreadcrumb$;
      }),
      filter((breadcrumb) => !!breadcrumb?.label && !!breadcrumb?.path),
      map((breadcrumbData) => this.modifyBreadcrumbs(breadcrumbData))
    );
  }

  private buildBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const breadcrumbValue = route.snapshot.data['breadcrumb'];
    const label =
      typeof route.snapshot.data['breadcrumb'] === 'function'
        ? breadcrumbValue(route.snapshot.data)
        : breadcrumbValue;

    const isHomePage = route.routeConfig?.path === '';
    const hasHomeBreadcrumb = breadcrumbs.some(
      (breadcrumb) => breadcrumb.label
    );

    if (hasHomeBreadcrumb && isHomePage) {
      return breadcrumbs;
    }

    const [segment, ...rest] = route.snapshot.url;

    let nextUrl = segment ? `${url}/${segment.path}` : url;

    if (rest.length > 0) {
      rest.forEach((param) => {
        nextUrl += `/${param.path}`;
      });
    }

    const { queryParams } = this.route.snapshot;

    const breadcrumb: Breadcrumb = {
      label,
      path: nextUrl,
      params: breadcrumbs.length > 1 && queryParams
    };

    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.path
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];

    if (route.firstChild) {
      return this.buildBreadcrumbs(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }

  setBreadcrumb(breadcrumb: Breadcrumb): void {
    this.currentBreadcrumb$.next(breadcrumb);
  }

  getBreadcrumbs$(): Observable<Breadcrumb[]> {
    return this._breadcrumbs$.pipe(
      map((breadcrumbs) =>
        breadcrumbs.filter((breadcrumb) => !!breadcrumb?.label)
      )
    );
  }

  private modifyBreadcrumbs(currentBreadcrumbData: Breadcrumb): Breadcrumb[] {
    return this._breadcrumbs$.value.map((breadcrumb) => {
      if (breadcrumb.path.includes(currentBreadcrumbData.path)) {
        return {
          ...breadcrumb,
          path: currentBreadcrumbData.params
            ? `${breadcrumb.path}`
            : breadcrumb.path,
          params: breadcrumb?.params ?? this.route.snapshot.queryParams,
          label: currentBreadcrumbData.label
        };
      } else {
        return {
          ...breadcrumb,
          params: this._breadcrumbs$.value.length < 2 && undefined
        };
      }
    });
  }

  private unsubscribe(): void {
    this.subscription.unsubscribe();
  }
}

export type Breadcrumb = {
  path: string;
  label: string;
  params?: { [key: string]: string };
};
